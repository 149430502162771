import React from 'react';

import { ButtonGroup } from '@/components/shared/buttons/button-group';
import { Line } from '@/components/shared/element/line';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { Grid } from '@/components/shared/layout/grid';
import { Section } from '@/components/shared/layout/section';
import { styled } from '@/stitches.config';
import { IButton } from '@/types/shared';

const StyledHeadline = styled('div', {
  variants: {
    isDescription: {
      false: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '$space-6',
        columnGap: '$space-6',
        '@lg': {
          flexDirection: 'row',
          justifyContent: 'space-between',
          '& > *:nth-child(1)': {
            flexGrow: 1,
            flexBasis: 0,
            maxWidth: '100%',
          },
          '& > *:nth-child(2)': {
            flexGrow: 0,
            flexBasis: 'max-content',
            '& > *': {
              justifyContent: 'flex-end',
            },
          },
        },
      },
    },
    isReverse: {
      true: {
        '@maxlg': {
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'flex-start',
        },
        '@lg': {
          alignItems: 'center',
        },
      },
    },
  },
});

const StyledHeadlineInner = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$space-6',
  columnGap: '$space-6',
  '@lg': {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& > *:nth-child(1)': {
      flexGrow: 1,
      flexBasis: 0,
      maxWidth: '100%',
    },
    '& > *:nth-child(2)': {
      flexGrow: 0,
      flexBasis: 'max-content',
      '& > *': {
        justifyContent: 'flex-end',
      },
    },
  },
});

interface HeadingLevel1Props
  extends React.ComponentProps<typeof StyledHeadline> {
  /**
   * The child components to render within the HeadingLevel1 component.
   */
  children?: React.ReactNode;

  /**
   * The title of the heading.
   */
  title: string;

  /**
   * The optional description to display below the title.
   */
  description?: string;

  /**
   * The array of buttons to render. Defaults to an empty array.
   */
  buttons?: IButton[];

  /**
   * Indicates if the theme is dark. Defaults to false.
   */
  isDarkTheme?: boolean;

  /**
   * The data tracking value for the heading.
   */
  dataTrack?: string;

  /**
   * The data tracking section for the heading.
   */
  dataTrackSection?: string;

  /**
   * The data tracking value for the heading.
   */
  dataTrackValue?: string;

  /**
   * The data test value for the heading. Defaults to 'heading'.
   */
  dataTest?: string;

  /**
   * The size of the heading. Defaults to 'md'.
   * @default 'md'
   */
  size?: 'md' | 'lg';
}

/**
 * Level 1 Heading for top-level page.
 *
 * @param {React.ReactNode} children - The child components to render within the button group.
 * @param {string} title - The title of the heading.
 * @param {string} [description] - The optional description to display below the title.
 * @param {IButton[]} [buttons=[]] - The array of buttons to render.
 * @param {boolean} [isDarkTheme=false] - Indicates if the theme is dark.
 * @param {string} [dataTrack] - The data tracking value for the heading.
 * @param {string} [dataTrackSection] - The data tracking section for the heading.
 * @param {string} [dataTrackValue] - The data tracking value for the heading.
 * @param {string} [dataTest='heading'] - The data test value for the heading.
 * @param {'md' | 'lg'} [size='md'] - The size of the heading.
 * @param {...rest} rest - The remaining props to pass to the StyledHeadline component.
 * @return {React.ReactElement} The rendered level 1 heading component.
 */
export const HeadingLevel1: React.FC<HeadingLevel1Props> = ({
  children,
  title,
  description,
  buttons = [],
  isDarkTheme = false,
  dataTrack,
  dataTrackSection,
  dataTrackValue,
  dataTest = 'heading',
  size = 'md',
  ...rest
}: HeadingLevel1Props) => {
  const getTitleVariant = () =>
    isDarkTheme ? 'text-white' : 'text-gray-darkest';

  const hasButtons = buttons.some((button) => button.label !== '');

  return (
    <>
      {!!title && (
        <Section as="div" size="sm" test={dataTest}>
          <Container>
            <StyledHeadline
              isDescription={!!description}
              isReverse={!!rest.isReverse}
              data-test={dataTest}
              {...rest}
            >
              {!!title && (
                <Text
                  as="h1"
                  size={size === 'lg' ? 'text-h1' : 'text-h2'}
                  variant={getTitleVariant()}
                  wrap
                  dangerouslySetInnerHTML={{ __html: title }}
                  data-test={`${dataTest}_title`}
                />
              )}
              <StyledHeadlineInner>
                {!!description && (
                  <Grid>
                    <Line />
                    <Text
                      as="div"
                      size={size === 'lg' ? 'text-sub-h2' : 'text-body-1'}
                      variant={getTitleVariant()}
                      font="bold"
                      wrap
                      dangerouslySetInnerHTML={{ __html: description }}
                      data-test={`${dataTest}_description`}
                    />
                  </Grid>
                )}
                {!!(hasButtons || children) && (
                  <Box>
                    <ButtonGroup
                      buttons={buttons}
                      variant="button-red"
                      isMinWidth
                      tracking={{
                        dataTrack: dataTrack,
                        dataTrackSection: dataTrackSection,
                        dataTrackValue: dataTrackValue,
                      }}
                      test={{
                        dataTest: `${dataTest}_button`,
                      }}
                    >
                      {children}
                    </ButtonGroup>
                  </Box>
                )}
              </StyledHeadlineInner>
            </StyledHeadline>
          </Container>
        </Section>
      )}
    </>
  );
};

HeadingLevel1.displayName = 'HeadingLevel1';
